@charset "utf-8";

// ==========================================================================
// Abstract
// ==========================================================================

@import "abstract/unit";
@import "abstract/variables";
@import "abstract/breakpoint";
@import "abstract/z-index";

:root {
  @each $name, $color in $webfatorial-colors {
    --color-#{$name}: #{$color};
  }

  @each $name, $color in $dpw-colors {
    --color-#{$name}: #{$color};
  }

  @each $name, $color in $social-media-colors {
    --color-#{$name}: #{$color};
  }
}

// ==========================================================================
// Vendor
// ==========================================================================

@import "vendor/normalize";

// ==========================================================================
// Base
// ==========================================================================

@import "base/elements";
@import "base/generics";

// ==========================================================================
// Layout
// ==========================================================================

// @import "layout/l-";

// ==========================================================================
// Component
// ==========================================================================

@import "component/c-carousel";

// ==========================================================================
// State
// ==========================================================================

// @import "state/is-";

// ==========================================================================
// Page
// ==========================================================================

// @import "page/";

// ==========================================================================
// Theme
// ==========================================================================

// @import "theme/t-";

// ==========================================================================
// Shame (hacks & dishonorable stuff)
// ==========================================================================
