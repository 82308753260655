*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 200dpi),
    only screen and (min-resolution: 1.25dppx) {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

*:focus {
  outline: none;
}

img,
iframe,
object,
embed,
video {
  height: auto;
  max-width: 100%;
}

object,
embed,
video {
  height: auto;
}

figure {
  margin: 0;
}

label {
  cursor: pointer;
}

sup,
sub {
  font-size: 50%;
}

// ==========================================================================
// Project specifics
// ==========================================================================

body {
  font-family: Nunito, sans-serif;
  font-size: $global-font-size;
}

// ::selection {
// }

// ::-moz-selection {
// }

// input::placeholder {}
// textarea::placeholder {}
// input::-webkit-input-placeholder {}
// textarea::-webkit-input-placeholder {}
// input::-moz-placeholder {}
// textarea::-moz-placeholder {}
// input:-ms-input-placeholder {}
// textarea:-ms-input-placeholder {}
